var isIp = require('is-ip');
/**
 * Load Jquery Trough Browerfiy
 * @type {exports}
 */
var $ = require('./libs/jquery');
var jQuery = $;
// Bind to Global Scope
window.$ = $;
window.jQuery = $;
function isIE() {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf('MSIE '); // IE 10 or older
    var trident = ua.indexOf('Trident/'); //IE 11
    return msie > 0 || trident > 0;
}
if (typeof window['dataLayer'] === 'undefined') {
    window['dataLayer'] = window['dataLayer'] || [];
}
if (typeof window['drs_dl'] === 'undefined') {
    window['drs_dl'] = window['drs_dl'] || [];
}
function ready(fn, fallback_fn) {
    if (document.readyState != 'loading') {
        if (!isIE()) {
            fn();
        }
        else {
            fallback_fn();
        }
    }
    else {
        if (!isIE()) {
            document.addEventListener('DOMContentLoaded', fn);
        }
        else {
            document.addEventListener('DOMContentLoaded', fallback_fn);
        }
    }
}
console.info('Fast.js loaded');
/**
 * Load External JS File into Header
 *
 * @param _path Path to JS File
 */
var loadJS = function (_path) {
    var po = document.createElement('script');
    po.type = 'text/javascript';
    po.async = true;
    po.src = _path;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(po, s);
};
ready(function () {
    var $sitemap = document.querySelectorAll('.drs-sitemap');
    if (!!$sitemap) {
        try {
            import(/* webpackChunkName: "drssitemap" */ './Module/drs_sitemap/sitemap_es6').then(function (module) {
                new module.DRSSitemap();
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    var $header = document.querySelectorAll('.drs-header');
    if (!!$header) {
        try {
            import(/* webpackChunkName: "drsheader" */ './Module/drs_header/header_es6').then(function (module) {
                new module.DRSHeader();
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    var $stage = document.querySelectorAll('.drs-stage');
    if (!!$stage) {
        try {
            import(/* webpackChunkName: "drsstage" */ './Module/drs-stage/stage_es6').then(function (module) {
                $stage.forEach(function (_node) {
                    new module.DRSStage(_node);
                });
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    var $banner = document.querySelectorAll('.drs-banner');
    if (!!$banner) {
        try {
            import(/* webpackChunkName: "drsbanner" */ './Module/drs_banner/banner_es6').then(function (module) {
                $banner.forEach(function (_node) {
                    new module.DRSBanner(_node);
                });
            });
        }
        catch (e) {
            console.error(e);
        }
    }
    // }
    //let $favoritesButtons = document.querySelectorAll('.favorites-button[data-favorites-show-as]');
    // let $favoritesButtons = document.querySelectorAll('[data-drs-show-module="favorites"]');
    // if ($favoritesButtons.length > 0) {
    //   try {
    //     import(/* webpackChunkName: "merkliste" */ './Module/Merkliste/merkliste').then(module => {
    //       new module.Merkliste();
    //       console.info('Merkliste Imported');
    //     });
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }
    import(/* webpackChunkName: "app" */ './app').then(function (module) {
        console.info('App.js Imported');
    });
    if (document.querySelectorAll('#drshssearch').length > 0) {
        import(/* webpackChunkName: "SearchApp" */ './Module/HochschulSuche/search.module').then(function (module) {
            console.info('Search.js Imported');
            new module.HSSearch();
        });
    }
    if (document.querySelectorAll('.map-wrapper').length > 0) {
        import(/* webpackChunkName: "Maps" */ './Module/MapProfil/Map2').then(function (module) {
            console.info('map.js Imported');
            new module.MapPfofile(document.querySelectorAll('.map-wrapper')[0]);
        });
    }
    // Fix Active Breadcrumb
    if (document.querySelector('[data-breadcrumb-active]')) {
        document.querySelector('.drs-breadcrumbs__list .isActive').innerHTML = document.querySelector('[data-breadcrumb-active]').getAttribute('data-breadcrumb-active');
    }
}, function () {
    /**
     * There is a separate File for IE that is not using the "New Include style" */
    loadJS('/typo3conf/ext/drs_template/Resources/Public/js/ie.js');
});
